<template>
  <div class="benefit-withdraw">
    <div class="content-inner">
      <div class="benefit-header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/web/user/benefit' }"
            >我的收益</el-breadcrumb-item
          >
          <el-breadcrumb-item>申请提现</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="dashboard">
        <div class="dashboard-item">
          <div class="label">可提现</div>
          <div class="money">{{ userInfo.can_withdraw }}</div>
        </div>
      </div>
      <el-form
        ref="withdraw-form"
        :inline="true"
        :model="withdrawForm"
        class="withdraw-form"
      >
        <el-form-item class="withdraw-form-item" label="姓名">
          <el-input v-model="withdrawForm.name"></el-input>
        </el-form-item>
        <el-form-item class="withdraw-form-item" label="所属银行">
          <el-select
            v-model="withdrawForm.bank"
            filterable
            allow-create
            default-first-option
            placeholder="请输入选择或选择银行"
          >
            <el-option
              v-for="item in bankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="withdraw-form-item" label="开户行">
          <el-input v-model="withdrawForm.bankopen"></el-input>
        </el-form-item>
        <el-form-item class="withdraw-form-item" label="账号">
          <el-input v-model="withdrawForm.user_name"></el-input>
        </el-form-item>
        <el-form-item class="withdraw-form-item" label="电话">
          <el-input v-model="withdrawForm.mobile"></el-input>
        </el-form-item>
        <el-form-item class="withdraw-form-item" label="提现金额">
          <el-input v-model="withdrawForm.money_withdraw"></el-input>
        </el-form-item>
        <el-form-item class="withdraw-form-item no-after" label=" ">
          <el-button @click="handleWithdraw">提交</el-button>
        </el-form-item>
        <el-form-item class="withdraw-form-item no-after" label=" ">
          <el-button @click="$router.back()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Input,
  Button,
  Select,
  Option
} from "element-ui";
import apis from "@/apis/index";

const initForm = () => ({
  bank: "",
  bankopen: "",
  name: "",
  user_name: "",
  mobile: "",
  money_withdraw: ""
});

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "BenefitWithdraw",
  components: {
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem,
    "el-form": Form,
    "el-form-item": FormItem,
    "el-input": Input,
    "el-button": Button,
    "el-select": Select,
    "el-option": Option
  },
  data() {
    return {
      withdrawForm: initForm(),
      bankOptions: [
        {
          label: "中国银行",
          value: "中国银行"
        },
        {
          label: "中国农业银行",
          value: "中国农业银行"
        },
        {
          label: "中国工商银行",
          value: "中国工商银行"
        },
        {
          label: "中国建设银行",
          value: "中国建设银行"
        },
        {
          label: "交通银行",
          value: "交通银行"
        },
        {
          label: "支付宝",
          value: "支付宝"
        }
      ]
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  activated() {
    this.withdrawForm = initForm();
  },
  methods: {
    async handleWithdraw() {
      await apis.userWithdraw(this.withdrawForm);
      this.$message.success("提交成功，请等待审核");
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss">
.withdraw-form-item {
  margin-bottom: 30px;

  &.no-after {
    .el-form-item__label {
      &::after {
        // display: none;
        opacity: 0;
      }
    }
  }

  .el-form-item__label {
    text-align: left;
    font-size: 12px;
    color: #333;

    &::after {
      content: "：";
    }
  }
}
</style>

<style lang="scss" scoped>
.benefit-withdraw {
  // margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100% - 90px);

  .content-inner {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
  }

  .benefit-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    .dashboard-item {
      width: 330px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(139, 66, 51, 0.5);
      border: 1px solid #CA0A15;
      border-radius: 4px;
      color: #ffffff;
      position: relative;

      .money {
        font-size: 36px;
        font-weight: bold;

        &::before {
          content: "¥";
          font-size: 14px;
        }
      }

      .label {
        font-size: 14px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .withdraw-form {
    display: flex;
    flex-flow: row wrap;
    margin: 0 20px;
  }

  .withdraw-form-item {
    flex: none;
    // width: 32.4%;
    display: flex;
    flex-flow: column nowrap;

    .el-form-item__label {
      text-align: left;
    }
  }
}
</style>
